import routes from './routes'
import pages from '../pages'

const appRoute = [
  {
    name: 'Welcome',
    path: routes.WELCOME,
    page: pages['Welcome']
  },
  {
    name: 'Home',
    path: routes.HOME,
    page: pages['Home']
  },
  {
    name: 'Laporan',
    path: routes.LAPOR,
    page: pages['Lapor']
  },
  {
    name: 'Profile',
    path: routes.PROFILE,
    page: pages['Profile']
  },
  {
    name: 'Login',
    path: routes.LOGIN,
    page: pages['Login']
  },
  {
    name: 'DetailLaporan',
    path: routes.DETAIL_LAPORAN,
    page: pages['Detail_Laporan']
  },
  {
    name: 'ProfileUpdate',
    path: routes.PROFILE_UPDATE,
    page: pages['Profile_Update']
  },
  {
    name: 'Verify',
    path: routes.VERIFY,
    page: pages['Verify']
  },
  {
    name: 'LaporanUpdate',
    path: routes.LAPORAN_UPDATE,
    page: pages['Laporan_Update']
  },
  {
    name: 'Bantuan',
    path: routes.BANTUAN,
    page: pages['Bantuan']
  }
]

export default appRoute