import { BASIC_PASSWORD, BASIC_USERNAME } from '../../configs/infra';
import services from '../../configs/services'

const headerReq = {
  'Content-Type': 'application/json',
  'Authorization': 'Basic ' + btoa(`${BASIC_USERNAME}:${BASIC_PASSWORD}`)
}

export const verifyUser = async (
  setIsVerify: (val: boolean) => void,
  tokenParam?: string
) => {
  try {
    const token = tokenParam ?? localStorage.getItem('warnotes_otp');
    const splitedToken = token?.split(':') ?? [];
    const phone = splitedToken[0];
    const otp = splitedToken[1];
    const requestOptions = {
      method: 'GET',
      headers: headerReq
    };
    const response: any = await fetch(`${services.GET_POST_OTP}?phone=${phone}&otp=${otp}`, requestOptions)
      .then(res => res.json())
    if(response.success) {
      setIsVerify(true)
    } else {
      window.location.href = '/login'
    }
  } catch (error) {
    setIsVerify(false)
    window.location.href = '/login'
  }
}

export const getProducts = async (
  setData: (val: any[]) => void, 
  setIsError: (val: any) => void) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: headerReq
    };
    const response: any = await fetch(`${services.GET_PRODUCTS}`, requestOptions)
      .then(res => res.json())

    if(!response.success) {
      setIsError(true)
      console.log(response);
      return;
    }

    setData(response.data);
  }catch(error) {
    console.log(error);
    return error;
  }
}

export const getUserByPhone = async (setIsAdmin: (val: any) => any) => {
  try {
    const token = localStorage.getItem('warnotes_otp');
    const splitedToken = token?.split(':') ?? [];
    const phone = splitedToken[0];

    const requestOptions = {
      method: 'GET',
      headers: headerReq
    };
    const response:any = await fetch(
        `${services.CRU_USER}/${phone}`,
        requestOptions
    );

    if (!response.ok) {
      throw new Error(`Error get user: ${response.status}`);
    }

    // Extract JSON data from the response
    const responseData = await response.json();

    setIsAdmin(responseData?.data?.isAdmin)

    return responseData;
  }catch(err) {
    return err;
  }
}