import services from '../../configs/services'
import { BASIC_PASSWORD, BASIC_USERNAME } from '../../configs/infra';

const headerReq = {
  'Content-Type': 'application/json',
  'Authorization': 'Basic ' + btoa(`${BASIC_USERNAME}:${BASIC_PASSWORD}`)
}

export const getProfileCheck = async (
  setFormData: any
) => {
  try {
    const token = localStorage.getItem('warnotes_otp');
    const splitedToken = token?.split(':') ?? [];
    const phone = splitedToken[0];

    const requestOptions = {
      method: 'GET',
      headers: headerReq
    };
    const response:any = await fetch(
        `${services.CRU_USER}/${phone}`,
        requestOptions
    );

    if (!response.ok) {
      throw new Error(`Error get user: ${response.status}`);
    }

    // Extract JSON data from the response
    const responseData = await response.json();

    setFormData({
      name: responseData.data.name,
      email: responseData.data.email,
      phone: responseData.data.phone
    })
    // setName(responseData.data.name)
    // setEmail(responseData.data.email)
    // setPhone(responseData.data.phone)

    return responseData;
  } catch (error) {
    console.log(error)
    return error;
  }
}
export const updateProfile = async (payload:any) => {
  try {
    const { name, email } = payload;
    const token = localStorage.getItem('warnotes_otp');
    const splitedToken = token?.split(':') ?? [];
    const phone = splitedToken[0];

    const requestOptions = {
      method: 'PUT',
      headers: headerReq,
      body: JSON.stringify({ email, name, phone })
    };
    const response: any = await fetch(`${services.CRU_USER}/${phone}`, requestOptions)
    setTimeout(
      () => window.location.href = "/profile",
      3000
    );
    console.log(response)

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log(error)
    return error;
  }
}
