import routes from '../../../configs/routes'

export const menuNavigation = [
  {
    name: 'Home',
    title: 'Beranda',
    path: routes.HOME,
  },
  {
    name: 'Profile',
    title: 'Profile',
    path: routes.PROFILE
  },
]