const routes = {
  WELCOME: '/',
  HOME: '/dashboard',
  LAPOR: '/laporan',
  PROFILE: '/profile',
  LOGIN: '/login',
  DETAIL_LAPORAN: '/laporan/detail',
  PROFILE_UPDATE: '/profile/update',
  VERIFY: '/verify',
  LAPORAN_UPDATE: '/laporan/update',
  BANTUAN: '/bantuan'
}

export default routes