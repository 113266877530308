import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import {verifyUser, getProfileCheck} from './actions'

import { BOT_NUMBER } from '../../configs/infra';

const Profile = (props: any) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [, setIsVerify] = useState(false);

  const doLogout = () => {
    localStorage.removeItem('warnotes_otp');
    window.location.href = '/'
  }

  useEffect(() => {
    verifyUser(setIsVerify);
    getProfileCheck(setName, setEmail, setPhone);
  }, [])

  return (
    <div id="profileContent">
      <div style={{marginTop: '22px'}}>
        <label className={name ? 'ok'  : 'danger'} htmlFor="name">{name ? ''  : '*'}Nama Lengkap</label>
        <input disabled className="form-group" type="text" placeholder="Nama lengkap belum diisi" value={name}/>
      </div>
      <div style={{marginTop: '12px'}}>
        <label className={email ? 'ok'  : 'danger'} htmlFor="email">{email ? ''  : '*'}Email</label>
        <input disabled className="form-group" type="text" placeholder="Email belum diisi" value={email}/>
      </div>
      <div style={{marginTop: '12px'}}>
        <label className='ok' htmlFor="name">No Whatsapp</label>
        <input disabled className="form-group" type="text" placeholder="No Whatsapp" value={phone} />
      </div>
      <br/>
      <Link to={'/profile/update'} key={'profile-update'}>
            <p style={{width: '120px', fontSize: '14px', color: '#37db35'}} className="button-lengkapi">Ubah Profile</p>
      </Link>

      <br />
      <div>
        <p className="title-section" style={{fontSize: '16px'}}>Butuh Bantuan? <br /> </p>
        <p style={{fontSize: '16px'}}> Hubungi Kami
          <a
            href={`https://api.whatsapp.com/send/?phone=${BOT_NUMBER}&text=Hai,%20Saya%20membutuhkan%20bantuan%20terkait%20warnotes&type=phone_number&app_absent=0`}
            target="_blank"
            rel="noopener noreferrer"
          >@cs.warnotes</a>
        </p>
      </div>

      <button onClick={() => doLogout()} className="btn-logout">KELUAR</button>
    </div>
  )
}

export default Profile
