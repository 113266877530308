import { colorPick, IIcon } from '../icons';
import * as React from 'react';

export const Home: React.FC<IIcon> = props => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M24.2736 14.3H22.1V22.1C22.1 22.6785 21.8478 23.4 20.8 23.4H15.6V15.6H10.4V23.4H5.19999C4.15219 23.4 3.89999 22.6785 3.89999 22.1V14.3H1.72639C0.948991 14.3 1.11539 13.8788 1.64839 13.3276L12.0796 2.88599C12.3331 2.62339 12.6659 2.49339 13 2.48039C13.3341 2.49339 13.6669 2.62209 13.9204 2.88599L24.3503 13.3263C24.8846 13.8788 25.051 14.3 24.2736 14.3Z" fill={colorPick(props.color || '')}/>
  </svg>
)

export default Home