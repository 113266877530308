const colors = {
    orange: {
      main: '#FE714C',
    },
    blue: {
      main: '#3497F9',
    },
    pink: {
      main: '#FF5C84',
      soft: '#FFEAEF',
    },
    green: {
      main: '#37db35',
    },
    primaryGreen: {
      main: '#37db35',
      main2: '#4CAF50'
    },
    black: {
      dark: '#29291E',
      main: '#535353',
      mid: '#C4C4C4',
      soft: '#D1D1D1',
      ligth: '#F6F6F6'
    },
  };
  
  export default colors;