import * as React from 'react';

const Content = (props: any) => {
  const { children } = props
  return (
    <div className="content-container">
      {children}
    </div>
  )
}

export default Content