import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import appRoute from './configs/appRoute'
import Layout from './layouts'
// import logo from './logo.svg';
import './App.css';
import ScrollToTop from './helpers/ScrollToTop'

function App() {
  const renderApp = () => (
    <Layout>
      <ScrollToTop></ScrollToTop>
      <Switch>
        {appRoute.map((item) => {
          const props = {
            exact: true,
            path: item.path,
            component: item.page
          }
          return <Route {...props} key={item.name} />
        })}
      </Switch>
    </Layout>
  )

  return (
    <Router>
      {renderApp()}
    </Router>
  );
}

export default App;
