import {
  Home,
  Book,
  Group,
} from '../Icons'
import * as React from 'react';

const NavIcon = (props: any) => {
  const { type, color } = props

  const iconProps = { color }

  switch (type) {
    case 'Laporan':
      return <Book {...iconProps} />
    case 'Profile':
      return <Group {...iconProps} />
    default:
      return <Home {...iconProps} />
  }
}

export default NavIcon