import { useEffect, useState } from 'react'
import { getProducts, getUserByPhone, verifyUser } from './actions'

import ClipLoader from "react-spinners/ClipLoader";

import ModalForm from '../../components/Modals/Modals';

const Home = (props: any) => {
  const [isVerifyAuth, setIsVerify] = useState(false);
  const [data, setData] = useState<any[]>([])
  const [isError, setIsError] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    verifyUser(setIsVerify);
	getProducts(setData, setIsError);
	getUserByPhone(setIsAdmin)
  }, [isVerifyAuth])

  const containerFiledPrice = {
	  overflow: 'hidden',
    display: 'flow',
    textOverflow: 'ellipsis',
    width: '140px'
  };

  return (
	<div className='app-background'>
			{isVerifyAuth === false ? (
				<ClipLoader
					color={'#37db35'}
					loading={true}
					cssOverride={{
						display: "block",
						margin: "0 auto",
					}}
					size={50}
					aria-label="Loading Spinner"
					data-testid="loader"
				/>
			) : (
			<div className='main-container'>
				{isAdmin && <ModalForm buttonLabel="Add" />}
				<div className='item-list'>
				{isError === false &&
					data?.map((item, index) => (
						<div className='item-container' key={index}>
							<ModalForm buttonLabel="View" payload={item} />
							<div className='item-name' onClick={() => alert("Show detail")}>
								<span style={containerFiledPrice}>Rp {item.price?.toLocaleString('id-ID')}</span>
							</div>
							<div className='quantity-idle'>
								<span> {item.stock} </span>
							</div>
							{isAdmin && 
								<div className='actionButton'>
									<ModalForm buttonLabel="Edit" payload={item} />
									<ModalForm buttonLabel="Delete" payload={item} />
								</div>
							}
						</div>
					))
				}
				</div>
			</div>
			)}
	</div>
  )
}

export default Home
