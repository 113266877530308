import { colorPick, IIcon } from '../icons';
import * as React from 'react';

export const Back: React.FC<IIcon> = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19 12H5" stroke={colorPick(props.color || '')} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 19L5 12L12 5" stroke={colorPick(props.color || '')} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default Back