import { colorPick, IIcon } from '../icons';
import * as React from 'react';

export const Book: React.FC<IIcon> = props => (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.97718 9.15527e-05C3.97648 9.15527e-05 3.97569 9.15527e-05 3.97499 9.15527e-05C3.72908 9.15527e-05 3.4978 0.0959504 3.32315 0.27016C3.14658 0.446295 3.04932 0.680821 3.04932 0.930492V14.8817C3.04932 15.3933 3.46716 15.8105 3.98085 15.8118C6.14648 15.817 9.77476 16.2684 12.2778 18.8877V4.28739C12.2778 4.11397 12.2335 3.95105 12.1499 3.81624C10.0955 0.507837 6.1477 0.00516901 3.97718 9.15527e-05Z" fill={colorPick(props.color || '')}/>
    <path d="M22.9508 14.8817V0.9304C22.9508 0.680729 22.8535 0.446203 22.6769 0.270068C22.5023 0.0958588 22.2708 0 22.0252 0C22.0244 0 22.0236 0 22.0229 0C19.8525 0.005165 15.9047 0.507833 13.8502 3.81624C13.7666 3.95105 13.7224 4.11397 13.7224 4.28739V18.8876C16.2254 16.2683 19.8537 15.8169 22.0193 15.8117C22.5329 15.8104 22.9508 15.3932 22.9508 14.8817Z" fill={colorPick(props.color || '')}/>
    <path d="M25.0698 3.21744H24.3952V14.8817C24.3952 16.1879 23.3309 17.253 22.0227 17.2563C20.1858 17.2606 17.157 17.6198 15.012 19.65C18.7218 18.7417 22.6327 19.3322 24.8615 19.8401C25.1398 19.9035 25.4274 19.838 25.6504 19.6603C25.8726 19.483 26 19.218 26 18.9336V4.14766C26.0001 3.63475 25.5827 3.21744 25.0698 3.21744Z" fill={colorPick(props.color || '')}/>
    <path d="M1.60483 14.8817V3.21744H0.930225C0.417402 3.21744 0 3.63475 0 4.14766V18.9333C0 19.2178 0.127462 19.4827 0.349644 19.66C0.57244 19.8376 0.859754 19.9034 1.13849 19.8398C3.36732 19.3318 7.27827 18.7414 10.988 19.6498C8.84301 17.6196 5.81421 17.2605 3.97731 17.2562C2.66917 17.253 1.60483 16.1879 1.60483 14.8817Z" fill={colorPick(props.color || '')}/>
  </svg>
)

export default Book