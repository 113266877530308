import { BASIC_PASSWORD, BASIC_USERNAME } from '../../configs/infra';
import services from '../../configs/services'
import formatCountryCodePhone from '../../helpers/setCountryCodePhone';

const username = BASIC_USERNAME;
const password = BASIC_PASSWORD;

export const verifyUser = async (
  setIsVerify: (val: boolean) => void,
) => {
  setIsVerify(true)
  try {
    const token = localStorage.getItem('warnotes_otp');
    const response: any = await fetch(`${services.VERIFY_USER}?token=${token}`)
      .then(res => res.json())

    setIsVerify(false)
    if(response.status) {
      setIsVerify(true)
    }
  } catch (error) {
    setIsVerify(false)
  }
}

const headerReq = {
  'Content-Type': 'application/json',
  'Authorization': 'Basic ' + btoa(`${username}:${password}`)
}

export const postVerifyUser = async (phone: String, setIsError: (val: any) => void) => {
  try {
    const countryCodePhone = formatCountryCodePhone(phone);

    const requestOptions = {
      method: 'POST',
      headers: headerReq,
      body: JSON.stringify({ phone: countryCodePhone })
    };
    const response: any = await fetch(services.GET_POST_OTP, requestOptions)
    return response;
  } catch (error) {
    setIsError(true)
    return error;
  }
}

export const verifyOTP = async (phone: String, otp: String, setIsError: (val: any) => void) => {
  try {
    const countryCodePhone = formatCountryCodePhone(phone);
  
    const requestOptions = {
      method: 'GET',
      headers: headerReq
    };
    const response: any = await fetch(`${services.GET_POST_OTP}?phone=${countryCodePhone}&otp=${otp}`, requestOptions)
    console.log(response)

    const responseData = await response.json();

    if(!responseData.success) {
      setIsError(true)
      return responseData;
    }

    const registerUsr = await registerUser(phone);
    if(!registerUsr.success) {
      setIsError(false);
      return registerUsr;
    }

    setIsError(false)
    localStorage.setItem('warnotes_otp', `${countryCodePhone}:${otp}`)
    setTimeout(
      () => window.location.href = '/dashboard', 
      3000
    );
    return responseData;
  } catch (error) {
    setIsError(true)
    return error;
  }
}

const registerUser = async (phone: String) => {
  try {
    const formatedPhone = formatCountryCodePhone(phone);

    const user:any = await getUserByPhone(formatedPhone);
    if(user.success) {
      return user;
    }

    const requestOptions = {
      method: 'POST',
      headers: headerReq,
      body: JSON.stringify({ phone: formatedPhone, name: `Guest-${new Date().getTime()}` })
    };
    const response = await fetch(
        `${services.CRU_USER}`,
        requestOptions
    );

    // Extract JSON data from the response
    const responseData = await response.json();

    return responseData;
  }catch(err) {
    return err;
  }
}

const getUserByPhone = async (phone: String) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: headerReq
    };
    const response:any = await fetch(
        `${services.CRU_USER}/${phone}`,
        requestOptions
    );

    if (!response.ok) {
      throw new Error(`Error get user: ${response.status}`);
    }

    // Extract JSON data from the response
    const responseData = await response.json();

    return responseData;
  }catch(err) {
    return err;
  }
}