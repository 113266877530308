const formatCountryCodePhone = (phone) => {
    if(phone.split('')[0] === '0') {
      let arr = phone.split('');
      arr.shift();
      phone = '62';
      for(let i = 0; i<arr.length; i++) {
        phone += arr[i]
      }
    }
  
    return phone;
};

export default formatCountryCodePhone;