import Home from './Home'
import Welcome from './Welcome'
import Profile from './Profile'
import Login from './Login'
import Profile_Update from './Profile_Update'

const pages = {
  Home,
  Welcome,
  Profile,
  Login,
  Profile_Update,
}

export default pages