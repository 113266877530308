import baseColor from '../../colors';
import * as React from 'react';

type TSecondaryColor = {
  [key: string]: string,
};

type TPrimaryColor = {
  [key: string]: TSecondaryColor,
};

const color: TPrimaryColor = baseColor;

type TColor = 'white' | 'orange-main' | 'blue-main' | 'pink-main' | 'pink-soft' | 'green-main' |
'black-main' | 'black-dark' | 'black-mid' | 'black-light' | 'black-soft' | 'blue-main' | 'blue-soft'

export const colorPick = (value: string) => {
  if (!value) return color.black.dark;

  if (value === 'white') return '#FFFFFF';

  const splitted: string[] = value.split('-')
  return color[splitted[0]][splitted[1]]
};

export interface IIcon extends React.SVGAttributes<SVGElement> {
  className?: string;
  color?: TColor;
  width?: number;
  height?: number;
}

export const Icon: React.FC<IIcon> = props => (
  <svg {...props} />
);

Icon.displayName = 'Icon'

export default Icon