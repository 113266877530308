const BASIC_USERNAME = process.env.REACT_APP_BASIC_USERNAME;
const BASIC_PASSWORD = process.env.REACT_APP_BASIC_PASSWORD;

const BASE_URL_API = process.env.REACT_APP_BASE_URL_API;
const BASE_URL_BOT_API = process.env.REACT_APP_BASE_URL_BOT_API;

const BOT_NUMBER = process.env.REACT_APP_BOT_PHONE_NUMBER

module.exports = {
    BASIC_USERNAME,
    BASIC_PASSWORD,
    BASE_URL_API,
    BASE_URL_BOT_API,
    BOT_NUMBER
}
