import React, { useState } from "react";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import './style.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { delProduct, postProduct, putProduct } from "./action";

Modal.setAppElement('#root');

function ModalForm(props) {
  const [modal, setModal] = useState(false);
  const [, setIsError] = useState(false);
  const [isErrorStockInput, setIsErrorStockInput] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    price: 0,
    stock: '',
    unit: '',
    description: '',
  });
  const [isProcessRequest, setProcessRequest] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const setCloseModal = () => {
    setModal(false);
    setIsErrorStockInput(false);
    setFormData({
      name: '',
      price: 0,
      stock: '',
      unit: '',
      description: ''
    });
    if(isProcessRequest) {
      window.location.reload();
      setProcessRequest(false);
    }else{
      setProcessRequest(false);
    }
  }

  const validateStockInput = (event) => {
    const stock = event.target.value;
    const regex = /^[0-9/]+$/; // Allow only numbers and slashes

    if (!regex.test(stock)) {
      setIsErrorStockInput(true);
    } else {
      setIsErrorStockInput(false);
    }
  };

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  //Toast
  const toastOpt:any = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored"
  };
  const showToastErr = (type = '') => {
    let msg = 'Terjadi kesalahan dalam melakukan proses';
    if(type === 'add') {
      msg = 'Gagal menambahkan produk';
    }else if(type === 'put') {
      msg = 'Gagal update produk';
    }else if(type === 'del') {
      msg = 'Gagal menghapus produk';
    }

    toast.error(msg, toastOpt);
  }
  const showToastSuccess = (type) => {
    let msg = 'Berhasil menambahkan produk';
    if(type === 'put') {
      msg = 'Berhasil merubah data produk';
    }else if(type === 'del') {
      msg = 'Berhasil menghapus produk';
    }

    toast.success(msg, toastOpt);
  }

  const postProductInput = async () => {
    try {
      setProcessRequest(true);
      const res = await postProduct(formData, setIsError)

      if(!res.ok) {
        setIsError(true);
        showToastErr('add');
      }else{
        showToastSuccess('add');
      }
    }catch(err) {
      console.error('Error menambahkan data:', err);
      setIsError(true);
      showToastErr();
    }
  };

  const deleteProduct = async () => {
    try {
      setProcessRequest(true);
      const res = await delProduct(payload?.id, setIsError);

      if(!res.ok) {
        setIsError(true);
        showToastErr('del');
      }else{
        showToastSuccess('del');
      }
    }catch(err) {
      console.error('Error menghapus data:', err);
      setIsError(true);
      showToastErr();
    }
  };

  const updateProduct = async () => {
    try{
      setProcessRequest(true);
      const res = await putProduct({
        id: payload?.id,
        name: formData.name ? formData.name : payload?.name,
        price: formData.price ? formData.price : payload?.price,
        stock: formData.stock ? formData.stock : (payload?.stock).toString(),
        unit: formData.unit ? formData.unit : payload?.unit,
        description: formData.description ? formData.description : payload?.description,
      }, setIsError);

      if (!res.ok) {
        setIsError(true);
        showToastErr('put');
      } else {
        showToastSuccess('put');
      }
    }catch(err) {
      console.error('An error occurred:', err);
      setIsError(true);
      showToastErr();
    }
  };

  const checkValidForm = () => {
    if(isProcessRequest) {
      return false
    }

    return (
      formData.name.length &&
      formData.price > 0 &&
      formData.stock.length &&
      formData.unit.length &&
      !isErrorStockInput
    );
  };

  const { buttonLabel: label, payload } = props;

  let button = <button/>;
  let title = "";

  const containerFiledName = {
    overflow: 'hidden',
    display: 'flow',
    textOverflow: 'ellipsis',
    width: '110px'
  };

  if (label === "Edit") {
    button = (
      <button className="buttonAction">
        <FontAwesomeIcon icon={faEdit} onClick={toggle} />
      </button>
    );
    title = "Edit Produk";
  } else if(label === "Delete") {
    button = (
      <button className="buttonAction" style={{color: '#c94040'}}>
        <FontAwesomeIcon icon={faTrash} onClick={toggle} />
      </button>
    );
    title = "Hapus Produk";
  } else if(label === 'Add') {
    button = (
      <div className='add-item-box' style={{flexDirection: 'row', justifyContent: 'space-between'}} onClick={toggle}>
        <p style={{color:'white', padding: '5px'}}>Tambah Produk</p>
        <FontAwesomeIcon icon={faPlus} />
      </div>
    );
    title = "Tambah Produk";
  }else{
    button = (
      <>
        <div className='item-name' onClick={toggle}>
          <span style={containerFiledName}>{payload.name}</span>
        </div>
      </>
    );
    title = "Detail Produk";
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
  };

  return (
    <div>
      {button}
      <Modal
        isOpen={modal}
        // onAfterOpen={{afterOpenModal}}
        onRequestClose={setCloseModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {
              label === 'Edit' ? (
                <form id="addInputModal" action="#" onSubmit={(e) => e.preventDefault()}>
                  <span className="closeButtonStyle" onClick={()=> setCloseModal()}>X</span>
                  <h3 style={{paddingBottom: '10px', marginTop: '15px'}}>{title}</h3>
                  <fieldset>
                    <input placeholder="Nama" type="text" tabIndex={1} required autoFocus defaultValue={payload?.name ?? ''} onChange={(e) => handleInputChange('name', e.target.value)}></input>
                  </fieldset>
                  <fieldset>
                    <input placeholder="Harga" type="number" min={0} tabIndex={2} required defaultValue={payload?.price ?? 0} onChange={(e) => handleInputChange('price', e.target.value)}></input>
                  </fieldset>
                  <fieldset>
                    <input placeholder="Stok" type="text" tabIndex={3} required defaultValue={payload?.stock} onChange={(e) => handleInputChange('stock', e.target.value)}></input>
                  </fieldset>
                  <fieldset>
                    <input placeholder="Unit" type="text" tabIndex={4} defaultValue={payload?.unit ?? ''} required onChange={(e) => handleInputChange('unit', e.target.value)}></input>
                  </fieldset>
                  <fieldset>
                    <textarea placeholder="Deskripsi Produk (Opsional)" tabIndex={4} required defaultValue={payload?.description ?? ''} onChange={(e) => handleInputChange('description', e.target.value)}></textarea>
                  </fieldset>
                  <fieldset>
                    <button type="submit" id="edit-submit" onClick={() => updateProduct()} disabled={isProcessRequest}>Update</button>
                  </fieldset>
                </form>
              ) : label === 'Delete' ? (
                <form id="addInputModal" action="#" onSubmit={(e) => e.preventDefault()}>
                  <span className="closeButtonStyle" onClick={()=> setCloseModal()}>X</span>
                  <h3 style={{paddingBottom: '10px', marginTop: '15px'}}>{title}</h3>
                  <fieldset>
                    <input placeholder="Nama" type="text" tabIndex={1} required autoFocus defaultValue={payload?.name} disabled></input>
                  </fieldset>
                  <fieldset>
                    <input placeholder="Harga" type="number" min={0} tabIndex={2} required defaultValue={payload?.price} disabled></input>
                  </fieldset>
                  <fieldset>
                    <input placeholder="Stok" defaultValue={payload?.stock} type="text" tabIndex={3} required disabled></input>
                  </fieldset>
                  <fieldset>
                    <input placeholder="Unit" defaultValue={payload?.unit} type="text" tabIndex={4} required disabled></input>
                  </fieldset>
                  <fieldset>
                    <textarea placeholder="Deskripsi Produk (Opsional)" tabIndex={4} required defaultValue={payload?.description ?? ''} disabled></textarea>
                  </fieldset>
                  <fieldset>
                    <button type="submit" id="delete-submit" onClick={() => deleteProduct()} disabled={isProcessRequest}>Hapus</button>
                  </fieldset>
                </form>
              ) : label === 'Add' ? (
                <form id="addInputModal" action="#" onSubmit={(e) => e.preventDefault()}>
                  <span className="closeButtonStyle" onClick={()=> setCloseModal()}>X</span>
                  <h3 style={{paddingBottom: '10px', marginTop: '15px'}}>{title}</h3>
                  <fieldset>
                    <input placeholder="Nama" type="text" tabIndex={1} required autoFocus onChange={(e) => handleInputChange('name', e.target.value)}></input>
                  </fieldset>
                  <fieldset>
                    <input placeholder="Harga" type="number" min={0} tabIndex={2} required onChange={(e) => handleInputChange('price', e.target.value)}></input>
                  </fieldset>
                  <fieldset>
                    <input placeholder="Stok" type="text" onChange={(e) => {
                      handleInputChange('stock', e.target.value);
                      validateStockInput(e);
                    }} tabIndex={3} required></input>
                    {isErrorStockInput && <p className="text-danger">Input angka atau satuan 1/2, 1/4, dll.)</p>}
                  </fieldset>
                  <fieldset>
                    <input placeholder="Unit" type="text" onChange={(e) => handleInputChange('unit', e.target.value)} tabIndex={4} required></input>
                  </fieldset>
                  <fieldset>
                    <textarea placeholder="Deskripsi Produk (Opsional)" onChange={(e) => handleInputChange('description', e.target.value)} tabIndex={5}></textarea>
                  </fieldset>
                  <fieldset>
                    <button disabled={!checkValidForm()} type="submit" id="add-submit" onClick={() => postProductInput()}>Tambah</button>
                  </fieldset>
                </form>
              ) : (
                <form id="addInputModal" action="#" onSubmit={(e) => e.preventDefault()}>
                  <span className="closeButtonStyle" onClick={()=> setCloseModal()}>X</span>
                  <h3 style={{paddingBottom: '10px', marginTop: '15px'}}>{title}</h3>
                  <fieldset>
                    <input placeholder="Nama" type="text" tabIndex={1} required defaultValue={payload?.name} disabled></input>
                  </fieldset>
                  <fieldset>
                    <input placeholder="Harga" type="number" min={0} tabIndex={2} required defaultValue={payload?.price} disabled></input>
                  </fieldset>
                  <fieldset>
                    <input placeholder="Stok" defaultValue={payload?.stock} type="text" tabIndex={3} required disabled></input>
                  </fieldset>
                  <fieldset>
                    <input placeholder="Unit" defaultValue={payload?.unit} type="text" tabIndex={4} required disabled></input>
                  </fieldset>
                  <fieldset>
                    <textarea placeholder="Deskripsi Produk (Opsional)" tabIndex={4} required defaultValue={payload?.description ?? ''} disabled></textarea>
                  </fieldset>
                </form>
              )
        }
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default ModalForm;
