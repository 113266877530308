import { colorPick, IIcon } from '../icons';
import * as React from 'react';

export const Group: React.FC<IIcon> = props => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13 12.4348C15.1851 12.4348 16.9565 10.6634 16.9565 8.47825C16.9565 6.29312 15.1851 4.52171 13 4.52171C10.8149 4.52171 9.04346 6.29312 9.04346 8.47825C9.04346 10.6634 10.8149 12.4348 13 12.4348Z" fill={colorPick(props.color || '')}/>
    <path d="M20.9131 14.6956C22.4739 14.6956 23.7392 13.4303 23.7392 11.8696C23.7392 10.3088 22.4739 9.04349 20.9131 9.04349C19.3523 9.04349 18.087 10.3088 18.087 11.8696C18.087 13.4303 19.3523 14.6956 20.9131 14.6956Z" fill={colorPick(props.color || '')}/>
    <path d="M5.16725 14.6956C6.72804 14.6956 7.99331 13.4303 7.99331 11.8696C7.99331 10.3088 6.72804 9.04349 5.16725 9.04349C3.60646 9.04349 2.34119 10.3088 2.34119 11.8696C2.34119 13.4303 3.60646 14.6956 5.16725 14.6956Z" fill={colorPick(props.color || '')}/>
    <path d="M12.9999 13.5652C8.94844 13.5652 5.6521 16.8616 5.6521 20.913C5.6521 21.225 5.90533 21.4783 6.21733 21.4783H19.7825C20.0945 21.4783 20.3478 21.225 20.3478 20.913C20.3478 16.8616 17.0514 13.5652 12.9999 13.5652Z" fill={colorPick(props.color || '')}/>
    <path d="M6.14165 15.9482C5.79573 15.8747 5.44415 15.8261 5.08695 15.8261C2.28235 15.8261 0 18.1084 0 20.913C0 21.225 0.253235 21.4783 0.565235 21.4783H4.62574C4.56241 21.3008 4.52172 21.112 4.52172 20.913C4.52172 19.058 5.12765 17.3454 6.14165 15.9482Z" fill={colorPick(props.color || '')}/>
    <path d="M20.9131 15.8261C20.5559 15.8261 20.2043 15.8747 19.8584 15.9482C20.8724 17.3454 21.4783 19.058 21.4783 20.913C21.4783 21.112 21.4376 21.3008 21.3743 21.4783H25.4348C25.7468 21.4783 26 21.225 26 20.913C26 18.1084 23.7177 15.8261 20.9131 15.8261Z" fill={colorPick(props.color || '')}/>
  </svg>
)

export default Group