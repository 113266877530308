import { useLocation, useHistory } from 'react-router-dom'
import routes from '../../../configs/routes'
import Logo from '../../../assets/img/logo.js'
import { Back } from '../../../components/Icons'
import Popup from '../../../components/Popup'
import { useState } from 'react';

const menuList = ['/', '/dashboard', '/laporan', '/profile', '/login', '/bantuan']

const Header = () => {
  const location = useLocation()
  const history = useHistory()
  const isMenu = menuList.some(menu => location.pathname === menu)
  const [isOpen, setIsOpen] = useState(false);
 
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  return (
    <div className="header-container">
      <div className="base-margin">
        {isMenu
          ? <a aria-label='go to home or dashboard' style={{ paddingLeft: '20px', paddingTop: '6px' }} href={location.pathname === '/' ? 'https://warnotes.my.id/' : routes.HOME}><Logo /></a>
          : (
            <button className="header-back" onClick={history.goBack}>
              <Back style={{ marginLeft: 15 }} height={24} width={24} />
            </button>
          )
        }
        {/* {isMenu && <Settings onClick={togglePopup} style={{ paddingRight: '20px', cursor: 'pointer' }} height={20} width={20} />} */}
      </div>
      {isOpen && 
        <Popup
          handleClose={togglePopup}
        />
      }
    </div>
  )
}

export default Header