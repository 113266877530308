import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import {getProfileCheck, updateProfile} from './actions'

const Profile_Update = (props: any) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: ''
  });

  const simpan = async() => {
    const payload = {
      name: formData.name,
      email: formData.email 
    }
    const res = await updateProfile(payload)
    if(!res.success) {
      showToastErr();
    }else{
      showToastSuccess();
    }
  }

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const validateInput = () => {
    const nameInput = formData.name;
    const emailInput = formData.email;
    return nameInput.length > 0 && emailInput.length > 0;
  };

  useEffect(() => {
    getProfileCheck(setFormData)
  }, [])

  const validInputCheck = validateInput();

  const validEmailInput = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(formData.email);
  }

  const validEmail = validEmailInput();

  //Toast
  const toastOpt:any = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored"
  };
  const showToastErr = () => {
    toast.error('Gagal melakukan update user', toastOpt);
  }
  const showToastSuccess = () => {
    toast.success('Berhasil update user', toastOpt);
  }

  return (
    <>
      <div id="profileContent">
        <div style={{marginTop: '22px'}}>
          <label className={formData.name ? 'ok'  : 'danger'} htmlFor="name">{formData.name ? ''  : '*'}Nama Lengkap</label>
          <input required className="form-group" type="text" placeholder="Nama lengkap belum diisi" defaultValue={formData.name} onChange={(e) => handleInputChange('name', e.target.value)}/>
        </div>
        <div style={{marginTop: '12px'}}>
          <label className={formData.email ? 'ok'  : 'danger'} htmlFor="email">{formData.email ? ''  : '*'}Email</label>
          <input required pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" className="form-group" type="text" placeholder="Email belum diisi" defaultValue={formData.email} onChange={(e) => handleInputChange('email', e.target.value)}/>
        </div>
        <div style={{marginTop: '12px'}}>
          <label className='ok' htmlFor="name">No Whatsapp</label>
          <input disabled className="form-group" type="text" placeholder="No Whatsapp" value={formData.phone} />
        </div>
        <br/>
        <Link to={'/profile/update'} key={'profile-update'}>
          <button disabled={!validInputCheck || !validEmail} onClick={simpan} className="btn-simpan-profile">
            Simpan
          </button>
        </Link>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  )
}

export default Profile_Update
