import './style.css'

const Popup = (props: any) => {
    const changeLanguage = (event: any) => {
        
    }
    return(
        <div className="popup-box">
            <div className="box">
                <span className="close-icon" onClick={props.handleClose}>x</span>
                <div>
                    <div onChange={changeLanguage}>
                        <label htmlFor="">Ubah Bahasa</label>
                        <br/>
                        <select> 
                            <option value="ina">Indonesia</option>
                            <option value="eng">English</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Popup;