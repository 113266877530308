import { BASE_URL_API } from './infra';

export const domain = BASE_URL_API;

const services = {
  VERIFY_USER: `${domain}/verify`,
  GET_POST_OTP: `${domain}/v1/otp`,
  GET_PRODUCTS: `${domain}/v1/products`,
  CUD_PRODUCT: `${domain}/v1/product`,
  CRU_USER: `${domain}/v1/user`
}

export default services
