import Header from './lib/Header'
import Navigation from './lib/Navigation'
import Content from './lib/Content'
import { useLocation } from 'react-router-dom'
import * as React from 'react';

const mainNav = ['/dashboard', '/laporan', '/profile']

const MainLayout = (props: any) => {
  const location = useLocation()
  return (
    <div style={{backgroundColor: '#f7f7f7', height: 'calc(100vh + 70px)'}}>
      <Header />
      <div style={{maxWidth: '480px', margin: '0 auto', backgroundColor: 'white'}}>
        <Content>
          {props.children}
        </Content>
        {mainNav.includes(location.pathname) && <Navigation />}
      </div>
    </div>
  )
}

export default MainLayout