import { BASIC_PASSWORD, BASIC_USERNAME } from '../../configs/infra';
import services from '../../configs/services'

const headerReq = {
  'Content-Type': 'application/json',
  'Authorization': 'Basic ' + btoa(`${BASIC_USERNAME}:${BASIC_PASSWORD}`)
}

export const verifyUser = async (
  setIsVerify: (val: boolean) => void,
) => {
  setIsVerify(true)
  try {
    const token = localStorage.getItem('warnotes_otp');
    const splitedToken = token?.split(':') ?? [];
    const phone = splitedToken[0];
    const otp = splitedToken[1];
    const requestOptions = {
      method: 'GET',
      headers: headerReq
    };
    const response: any = await fetch(`${services.GET_POST_OTP}?phone=${phone}&otp=${otp}`, requestOptions)
      .then(res => res.json())

    setIsVerify(false)
    if(response.success) {
      setIsVerify(true)
    } else {
      window.location.href = '/login'
    }
  } catch (error) {
    setIsVerify(false)
    window.location.href = '/login'
  }
}

export const getProfileCheck = async (
  setName: any,
  setEmail: any,
  setPhone: any,
) => {
  try {
    const token = localStorage.getItem('warnotes_otp');
    const splitedToken = token?.split(':') ?? [];
    const phone = splitedToken[0];

    const requestOptions = {
      method: 'GET',
      headers: headerReq
    };
    const response:any = await fetch(
        `${services.CRU_USER}/${phone}`,
        requestOptions
    );

    if (!response.ok) {
      throw new Error(`Error get user: ${response.status}`);
    }

    // Extract JSON data from the response
    const responseData = await response.json();

    setName(responseData.data.name)
    setEmail(responseData.data.email)
    setPhone(responseData.data.phone)

    return responseData;
  } catch (error) {
    console.log(error)
  }
}