import { BASIC_PASSWORD, BASIC_USERNAME } from '../configs/infra';
import services from '../configs/services'

export const verifyUser = async (tokenParam) => {
    try {
      const token = tokenParam ?? localStorage.getItem('warnotes_otp');
      const splitedToken = token?.split(':') ?? [];
      const phone = splitedToken[0];
      const otp = splitedToken[1];
      const headerReq = {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(`${BASIC_USERNAME}:${BASIC_PASSWORD}`)
      }
      const requestOptions = {
        method: 'GET',
        headers: headerReq
      };
      const response = await fetch(`${services.GET_POST_OTP}?phone=${phone}&otp=${otp}`,
        requestOptions).then(res => res.json())
  
      if(response.success) {
        return response;
      } else {
        alert('Waktu sesi anda habis');
        window.location.href = '/login'
      }
    } catch (error) {
      alert('Waktu sesi anda habis');
      window.location.href = '/login'
    }
  }