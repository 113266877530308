import { BASIC_PASSWORD, BASIC_USERNAME } from '../../configs/infra';
import { verifyUser } from "../../helpers/verifyUser";
import services from '../../configs/services'

const username = BASIC_USERNAME;
const password = BASIC_PASSWORD;

const headerReq = {
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa(`${username}:${password}`)
}

export const postProduct = async (payload: any, setIsError: (val: any) => void) => {
    try {
      const token = localStorage.getItem('warnotes_otp');
      await verifyUser(token);

      const splitedToken = token?.split(':') ?? [];
      const phone = splitedToken[0];

      const requestOptions = {
        method: 'POST',
        headers: headerReq,
        body: JSON.stringify({
            ...payload,
            createdBy: phone,
            updatedBy: phone
        })
      };
      const response: any = await fetch(services.CUD_PRODUCT, requestOptions)
      return response;
    } catch (error) {
      setIsError(true)
      return error;
    }
}

  export const delProduct = async (id: string, setIsError: (val: any) => void) => {
    try {
      const token = localStorage.getItem('warnotes_otp');
      await verifyUser(token);

      const requestOptions = {
        method: 'DELETE',
        headers: headerReq
      };
      const response: any = await fetch(`${services.CUD_PRODUCT}/${id}`, requestOptions)
      return response;
    } catch (error) {
      setIsError(true)
      return error;
    }
}

export const putProduct = async (payload: any, setIsError: (val: any) => void) => {
    try {
      const token = localStorage.getItem('warnotes_otp');
      await verifyUser(token);

      const splitedToken = token?.split(':') ?? [];
      const phone = splitedToken[0];

      const id = payload.id;
      delete payload.id;

      const requestOptions = {
        method: 'PUT',
        headers: headerReq,
        body: JSON.stringify({
            ...payload,
            updatedBy: phone
        })
      };
      const response: any = await fetch(`${services.CUD_PRODUCT}/${id}`, requestOptions)
      return response;
    } catch (error) {
      setIsError(true)
      return error;
    }
}