import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags'
import { BOT_NUMBER } from "../../configs/infra"

const Welcome = (props: any) => {
  return (
    <div className="welcome-margin">
      <div>

      </div>

      <div>
        <MetaTags>
          <title>Warnotes</title>
          <meta name="description" content="Catat katalog barangmu dengan mudah hanya di Warnotes" />
          <meta property="og:title" content="Warnotes Catat katalog barang dagangan di Whatsapp" />
          <meta name="keyword" content="Warnotes, catat katalog barang dagangan, whatsapp, catat dagangan, atur keuangan mudah, catat keuangan" />
        </MetaTags>
        {/* <h1>Sedang Dalam Maintenance</h1> */}
        <h1>Warnotes</h1>
        <p>Catat katalog barang melalui <b style={{letterSpacing: '-1px'}}>Whatsapp</b></p>

        <div className="row mt-3" style={{marginTop: '40px'}}>
          <a
            href={`https://api.whatsapp.com/send/?phone=${BOT_NUMBER}&text=Hai%20Warnotes&type=phone_number&app_absent=0`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="button-primary mr-1">Catat Sekarang</button>
          </a>
          <Link to={'/dashboard'} key={'dashboard'}>
            <button className="button-secondary" style={{marginLeft: '15px'}}>Masuk ke Dashboard</button>
          </Link>
        </div>
      </div>

      <div style={{marginBottom: '-15px'}}>
        <p style={{fontSize: '16px'}}>Butuh Bantuan? Hubungi Kami <a
            href={`https://api.whatsapp.com/send/?phone=${BOT_NUMBER}&text=Hai,%20Saya%20membutuhkan%20bantuan%20terkait%20warnotes&type=phone_number&app_absent=0`}
            target="_blank"
            rel="noopener noreferrer"
          >@cs.warnotes</a>
        </p>
      </div>
    </div>
  )
}

export default Welcome
