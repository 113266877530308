import { useEffect, useState } from "react"
import { postVerifyUser, verifyOTP } from "./action"

import OtpInput from 'react-otp-input';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = (props: any) => {
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [showError, setShowError] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isError, setIsError] = useState(false);
  const [countResendOTP, setCountResendOTP] = useState(30);

  const showToastErr = () => {
    toast.error('Gagal melakukan proses. Silahkan coba lagi nanti.', {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  const showToastSuccess = () => {
    toast.success('Verifikasi OTP berhasil', {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  const verifyUser = async () => {
    let isErrorReq = false;
    await postVerifyUser(phone, setIsError).then((res) => {
      if(!res.ok) {
        setIsError(true);
        isErrorReq = true;
        return;
      }
    })

    if(isErrorReq) {
      return showToastErr();
    }

    setIsVerified(true)
  }

  const verifyUserOTP = async () => {
    let isErrorReq = false;
    await verifyOTP(phone, otp, setIsError).then((res) => {
      if(!res.success) {
        setIsError(true);
        isErrorReq = true;
        return;
      }
      console.log(res)
    })

    if(isErrorReq) {
      return showToastErr();
    }

    setIsVerified(true)
    showToastSuccess()
  }

  const resendOTP = async () => {
    setIsVerified(false);
    setIsError(false);
    setPhone('');
    setOtp('');
    setCountResendOTP(30);
  }

  const checkValidPhone = () => {
    if([10, 11, 12, 13].includes(phone.length) && phone.startsWith('08')) {
      return true;
    }
    return false;
  }

  const validPhone = checkValidPhone();

  useEffect(() => {
    if(phone.length > 13) {
      setShowError(true);
    } else {
      if(phone.length > 0) {
        if(phone.split('')[0] === '0') {
          setShowError(false);
        } else {
          setShowError(true);
        }
      }
    }

    if(isVerified) {
      let seconds = countResendOTP;

      if (seconds > 0) {
        const intervalId = setInterval(() => {
          seconds--;
          setCountResendOTP(seconds);

          if (seconds === 0) {
            clearInterval(intervalId);
          }
        }, 1000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }

  }, [showError, phone, countResendOTP, isVerified, isError])

  return (
    <div>
      {
      isVerified ? (
        <div>
          <p>Kode verifikasi berhasil dikirimkan ke nomor whatsapp <b>{phone}</b>.</p>
          <br/>
          <p>Masukan kode OTP:</p>
          <OtpInput
            inputStyle={'otp-input'}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
          />
          <br/>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>
      )
      : <p className='title-section'>Masuk untuk nikmati kemudahan catat keuangan dan akses fitur lainnya</p>
      }
      {isVerified && isError === true &&
        <div>
          <p style={{marginTop: '-10px', marginBottom: '15px'}} className="text-danger">Kode OTP tidak valid</p>
        </div>
      }

      {!isVerified &&
      <>
        <input required title="gunakan nomor yang valid (08xx)" pattern="^08[1-9][0-9]{7,10}$" onChange={(e: any) => setPhone(e.target.value  )} className={phone.length > 0 ? 'input_login input_login_activated': 'input_login'} type="number" placeholder="Masukan Nomor Whatsapp" />
        {showError &&
          <p className="text-danger">Gunakan nomor yang valid (08xx)</p>
        }
        <button disabled={validPhone && !showError ? false : true}  className={validPhone && !showError ? 'btn-lanjutkan btn-actived': 'btn-lanjutkan'} onClick={() => verifyUser()}>Lanjutkan</button>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
      }

      {isVerified && countResendOTP > 0 &&
      <>
        <p className="mini-title">Tidak Menerima Pesan? <span className="mini-title-disabled">Kirim Ulang ({countResendOTP})</span></p>
      </>
      }
      {isVerified && countResendOTP === 0 &&
      <>
        <p className="mini-title">Tidak Menerima Pesan? <a onClick={() => resendOTP()} className="mini-title-activated" href="#/">Kirim Ulang</a></p>
      </>
      }
      

      {isVerified &&
      <>
        <button disabled={otp.length === 6 && !showError ? false : true}  className={otp.length === 6 && !showError ? 'btn-lanjutkan btn-actived': 'btn-lanjutkan'} onClick={() => verifyUserOTP()}>Lanjutkan</button>
      </>
      }
      
    </div>
  )
}

export default Login
