import { menuNavigation } from './constant'
import { Link, useLocation } from 'react-router-dom'
import NavIcon from '../../../components/NavIcon'
import * as React from 'react';

interface IMenuProps {
  name: string;
  path: string;
  title: string;
  contains?: string[];
}

const Navigation = () => {
  const location = useLocation()
  const { pathname } = location

  const menuItem = (menu: IMenuProps) => {    
    const active = pathname === menu.path || menu.contains?.some(item => pathname.includes(item))
    return (
      <Link to={menu.path} className="navigation-item" key={menu.name}>
        <NavIcon type={menu.name} color={active ? 'green-main' : 'black-mid'} />
        <span className={active ? 'navigation-item-title-active' : 'navigation-item-title'}>{menu.title}</span>
      </Link>
    )
  }

  return (
    // pathname !== '/' ? 
      <div className="navigation-container">
        {menuNavigation.map((menu: any) => menuItem(menu))}
      </div>
    //  : <></>
  )
}

export default Navigation